<template>
  <div class="table-responsive" v-if="items">
    <table class="table">
      <thead>
        <tr>
          <th class="text-center">รายละเอียดตามผู้ติดต่อ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            <div class="mt-1 mb-2">
              <h6 class="text-primary">
                {{item.code}} ({{item.name}})
              </h6>
            </div>
            <ListReportKpiProgress
              class="my-1"
              v-for="kpi in item.kpis"
              :key="kpi.name"
              :percent="kpi.percent"
              :name="kpi.name"
              :remarks="kpi.remarks">
            </ListReportKpiProgress>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ListReportKpiProgress from './ListReportKpiProgress'

export default {
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    ListReportKpiProgress
  }
}
</script>

<style lang="css" scoped>
</style>
