<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="kpiFilter.startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.kpiFilter.startDate.$dirty && $v.kpiFilter.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="kpiFilter.endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.kpiFilter.endDate.$dirty && $v.kpiFilter.endDate.$error}
        ]">
      </sgv-input-date>

      <ListReportKpiContact
        label="ผู้ถูกประเมิน"
        placeholder="เลือกผู้ติดต่อ..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="kpiFilter.contactId">
        <template slot-scope="option">
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </ListReportKpiContact>
    </div>

    <button class="btn btn-warning" @click="fetchData">
      ค้นหา
    </button>

    <ListReportKpiSummary :items="items" class="mt-3"></ListReportKpiSummary>

    <ListReportKpiByContact :items="items" class="mt-3"></ListReportKpiByContact>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import ListReportKpiContact from './ListReportKpiContact'
import ListReportKpiByContact from './ListReportKpiByContact.vue'
import ListReportKpiSummary from './ListReportKpiSummary.vue'
import {
  LIST_REPORT_KPI
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReportKpi`,
      kpiFilter: {
        startDate: '',
        endDate: '',
        contactId: null,
      },
      rFields: ['kpiFilter'],
      items: []
    }
  },
  validations: {
    kpiFilter: {
      startDate: {required},
      endDate: {required},
    }
  },
  methods: {
    fetchData () {
      this.$v.kpiFilter.$touch()
      if (this.$v.kpiFilter.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_KPI(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.$date.format(this.kpiFilter.startDate).utc,
          endDate: this.$date.format(this.kpiFilter.endDate, 0, 1).utc,
          contactId: this.kpiFilter.contactId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    }
  },
  components: {
    ListReportKpiContact,
    ListReportKpiByContact,
    ListReportKpiSummary
  }
}
</script>

<style lang="css" scoped>
</style>
