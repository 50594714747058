<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-4"
        v-model="searchFilter.startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.searchFilter.startDate.$dirty && $v.searchFilter.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-4"
        v-model="searchFilter.endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.searchFilter.endDate.$dirty && $v.searchFilter.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-6 col-sm-4"
        v-model="searchFilter.search"
        label="ตัวกรอง">
      </sgv-input-text>
    </div>

    รายงาน
    <div class="form-row">
      <div class="col-12">
        <sgv-input-check
          label="สรุป"
          inline
          v-model="optionsFilter.isSummary">
        </sgv-input-check>

        <sgv-input-check
          label="รายเอกสาร"
          inline
          v-model="optionsFilter.isDoc">
        </sgv-input-check>
      </div>

      <div class="form-group col-12">
        <sgv-input-check
          label="สรุปสินค้า"
          inline
          v-model="optionsFilter.isSummaryInventory">
        </sgv-input-check>

        <sgv-input-check
          label="รายเอกสาร-สินค้า"
          inline
          v-model="optionsFilter.isDocInventory">
        </sgv-input-check>
      </div>
    </div>

    <ListReportPrinter
      class="mb-3"
      :templateType="templateType"
      :docType="docType"
      @search="fetchData"
      @print="printData"
      @download="downloadData">
      ค้นหา
    </ListReportPrinter>

    <div v-if="isFetching">
      กำลังค้นหา...
    </div>

    <div v-else>
      <ListReportPurchaseSummary
        class="mt-3"
        v-if="report.summary.length > 0"
        :items="report.summary">
      </ListReportPurchaseSummary>

      <ListReportPurchaseDoc
        class="mt-3"
        v-if="report.doc.length > 0"
        :items="report.doc">
      </ListReportPurchaseDoc>

      <ListReportPurchaseSummaryInventory
        class="mt-3"
        v-if="report.summaryInventory.length > 0"
        :items="report.summaryInventory">
      </ListReportPurchaseSummaryInventory>

      <ListReportPurchaseDocInventory
        class="mt-3"
        v-if="report.docInventory.length > 0"
        :items="report.docInventory">
      </ListReportPurchaseDocInventory>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_PURCHASE } from './graph/report'
import ListReportPrinter from '@/views/doc_core/components/ListReportPrinter'
import ListReportPurchaseSummary from './ListReportPurchaseSummary'
import ListReportPurchaseSummaryInventory from './ListReportPurchaseSummaryInventory'
import ListReportPurchaseDoc from './ListReportPurchaseDoc'
import ListReportPurchaseDocInventory from './ListReportPurchaseDocInventory'


export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      report: {
        summary: [],
        summaryInventory: [],
        doc: [],
        docInventory: []
      },
      searchFilter: {
        startDate: '',
        endDate: '',
        search: '',
      },
      optionsFilter: {
        isDoc: false,
        isDocInventory: false,
        isSummary: false,
        isSummaryInventory: false,
      },
      isFetching: false,
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportPurchase`,
      rFields: ['searchFilter', 'optionsFilter'],
      url: `/doc/${this.docType}/print/report/purchase`
    }
  },
  validations: {
    searchFilter: {
      startDate: {required},
      endDate: {required}
    }
  },
  methods: {
    serializeInput () {
      const filter = {...this.searchFilter}

      const options = {}

      if (this.optionsFilter.isSummary) {
        options.isSummary = true
      }

      if (this.optionsFilter.isSummaryInventory) {
        options.isSummaryInventory = true
      }

      if (this.optionsFilter.isDoc) {
        options.isDoc = true
      }

      if (this.optionsFilter.isDocInventory) {
        options.isDocInventory = true
      }

      return {filter, options}
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()

      this.report.summary = []
      this.report.summaryInventory = []
      this.report.doc = []
      this.report.docInventory = []

      this.isFetching = true

      this.$apollo.query({
        query: LIST_REPORT_PURCHASE(this.templateType),
        variables: {
          ...this.serializeInput(),
          docType: this.docType
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.report.summary = res.data.report.summary
        this.report.summaryInventory = res.data.report.summaryInventory
        this.report.doc = res.data.report.doc
        this.report.docInventory = res.data.report.docInventory
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isFetching = false
      })
    },
    downloadData () {
      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options
        },
        responseType: 'blob'
      })
      .then(res => {
        let filename = `${this.docType}_report_ledger.pdf`
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      const input = this.serializeInput()

      this.$axios.get(this.url, {
        params: {
          ...input.filter,
          ...input.options,
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    }
  },
  components: {
    ListReportPrinter,
    ListReportPurchaseSummary,
    ListReportPurchaseSummaryInventory,
    ListReportPurchaseDoc,
    ListReportPurchaseDocInventory,
  }
}
</script>

<style lang="css" scoped>
</style>
