<template>
  <div class="table-responsive" v-if="items">
    <table class="table">
      <thead>
        <tr>
          <th class="text-center">สรุป</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ListReportKpiProgress
              class="my-1"
              v-for="kpi in summary"
              :key="kpi.name"
              :percent="kpi.percent"
              :name="kpi.name"
              :remarks="kpi.remarks">
            </ListReportKpiProgress>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ListReportKpiProgress from './ListReportKpiProgress'

export default {
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    summary () {
      return this.items
      .flatMap(v => v.kpis)
      .reduce((t,v) => {
        const found = t.find(x => x.name === v.name)
        if (!found) {
          t.push({
            name: v.name,
            percents: [v.percent],
            remarks: [...v.remarks]
          })
        } else {
          found.percents.push(v.percent),
          found.remarks = [...found.remarks, ...v.remarks]
        }
        return t
      }, [])
      .map(kpi => {
        return {
          name: kpi.name,
          percent: kpi.percents.reduce((t,v) => +t + +v) / kpi.percents.length,
          remarks: kpi.remarks
        }
      })
    }
  },
  components: {
    ListReportKpiProgress
  }
}
</script>

<style lang="css" scoped>
</style>
